import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'pl-oauth-signin-callback',
  template: `<p>Processing signin callback</p>`,
  styles: [],
  //standalone: true,
  //imports: [],
})
export class SigninCallbackComponent implements OnInit {
  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {
    this.authService.userManager
      .signinCallback()
      .then(response => {
        console.log(response);
      })
      .finally(() => {
        this.router.navigate(['/oauth']);
      });
  }
}
