import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppStore } from '@app/appstore.model';
import { User } from '@modules/user/user.model';
import { PLMayService, PLUrlsService } from '@root/index';
import { UserType } from '@root/src/lib-components/pl-may/pl-may.service';
import { selectCurrentUser } from '../../store';

@Component({
  selector: 'pl-side-nav',
  templateUrl: './pl-side-nav.component.html',
  styleUrls: ['./pl-side-nav.component.less'],
})
export class PLSideNavComponent implements OnInit {
  @Input() navCollapsed: boolean;
  currentUser: User;
  userType: UserType;
  homeUrl: string;
  links: any[];
  mouseInside: boolean = false;
  mouseEnterTimeout: any;
  autoExpandSidenav: boolean = false;

  constructor(
    private plUrls: PLUrlsService,
    private plMayService: PLMayService,
    private store: Store<AppStore>,
  ) {}

  onMouseEnter() {
    if (this.autoExpandSidenav) {
      clearTimeout(this.mouseEnterTimeout);
      this.mouseEnterTimeout = setTimeout(() => {
        this.mouseInside = true;
      }, 300);
    }
  }

  onMouseLeave() {
    if (this.autoExpandSidenav) {
      clearTimeout(this.mouseEnterTimeout);
      this.mouseInside = false;
    }
  }

  getLandingPage(userType: UserType) {
    switch (userType) {
      case UserType.CAM:
        return '/cam-dashboard/overview';
      case UserType.CUSTOMER:
        return '/dashboard';
      default:
        return '/landing';
    }
  }

  getClientLandingPage(userType: UserType) {
    const shouldGoToCaseloadClients = [
      UserType.PROVIDER,
      UserType.THERAPIST,
    ].includes(userType);

    return shouldGoToCaseloadClients
      ? '/client/list/caseload-clients'
      : '/client/list/all-clients';
  }

  ngOnInit() {
    this.store.select(selectCurrentUser).subscribe((user: any) => {
      this.currentUser = user;
      this.userType = this.plMayService.getUserType(user);

      console.log(UserType.CAM, UserType.CUSTOMER, this.userType);
      this.homeUrl = this.getLandingPage(this.userType);

      this.links = [
        {
          icon: 'home',
          label: 'Home',
          filter: () => true,
          href: this.homeUrl,
          children: [
            {
              label: 'Overview',
              filter: (userType: UserType) =>
                [UserType.SUPERUSER, UserType.CAM].includes(userType),
              href: '/cam-dashboard/overview',
            },
            {
              label: 'Assignment Manager',
              filter: (userType: UserType) =>
                [UserType.SUPERUSER, UserType.CAM].includes(userType),
              href: '/assignment-manager',
            },
            {
              label: 'Scheduling status',
              filter: (userType: UserType) =>
                [UserType.SUPERUSER, UserType.CAM].includes(userType),
              href: '/cam-dashboard/locations-scheduling-status',
            },
          ],
        },
        {
          icon: 'supervisor_account',
          label: 'Students',
          filter: (userType: UserType) =>
            [UserType.SUPERUSER, UserType.CUSTOMER].includes(userType),
          href: '/client/list/all-clients',
          children: [
            {
              label: 'All students',
              filter: (userType: UserType) =>
                [UserType.SUPERUSER, UserType.CUSTOMER].includes(userType),
              href: '/client/list/all-clients',
            },
            {
              label: 'My caseload',
              filter: (userType: UserType) =>
                [UserType.SUPERUSER].includes(userType),
              href: '/client/list/caseload-clients',
            },
            {
              label: 'Absences',
              filter: (userType: UserType) =>
                [UserType.SUPERUSER, UserType.CUSTOMER].includes(userType),
              href: '/client/list/absences',
            },
          ],
        },
        {
          icon: 'supervisor_account',
          label: 'Clients',
          filter: (userType: UserType) =>
            [
              UserType.PROVIDER,
              UserType.THERAPIST,
              UserType.CAM,
              UserType.SUPPORT,
            ].includes(userType),
          href: this.getClientLandingPage(this.userType),
          aliases: ['/client'],
          children: [
            {
              label: 'All clients',
              filter: (userType: UserType) =>
                [
                  UserType.PROVIDER,
                  UserType.THERAPIST,
                  UserType.CAM,
                  UserType.SUPPORT,
                ].includes(userType),
              href: '/client/list/all-clients',
            },
            {
              label: 'My caseload',
              filter: (userType: UserType) =>
                [UserType.PROVIDER, UserType.THERAPIST].includes(userType),
              href: '/client/list/caseload-clients',
            },
            {
              label: 'Assessments',
              filter: (userType: UserType) =>
                [UserType.CAM, UserType.SUPPORT].includes(userType),
              href: '/client/list/assessments',
            },
            {
              label: 'My assessments',
              filter: (userType: UserType) =>
                [UserType.PROVIDER, UserType.THERAPIST].includes(userType),
              href: '/client/list/assessments',
            },
            {
              label: 'Absences',
              filter: (userType: UserType) =>
                [UserType.CAM, UserType.SUPPORT].includes(userType),
              href: '/client/list/absences',
            },
            {
              label: 'Open referrals',
              filter: (userType: UserType) =>
                [
                  UserType.SUPERUSER,
                  UserType.PROVIDER,
                  UserType.THERAPIST,
                ].includes(userType),
              href: '/client-referrals/open',
            },
          ],
        },
        {
          icon: 'assignment',
          label: 'Assignments',
          filter: (userType: UserType) =>
            [UserType.PROVIDER, UserType.THERAPIST].includes(userType),
          href: '/assignments',
        },
        {
          icon: 'person_add',
          label: 'Referral Manager',
          filter: (userType: UserType) =>
            [UserType.SUPERUSER, UserType.CAM, UserType.SUPPORT].includes(
              userType,
            ),
          href: '/client-referrals/manager',
        },
        {
          icon: 'supervisor_account',
          label: 'Providers',
          filter: (userType: UserType) =>
            [UserType.SUPERUSER, UserType.CAM, UserType.SUPPORT].includes(
              userType,
            ),
          href: '/providers',
        },
        {
          icon: 'maps_home_work',
          label: 'Locations',
          filter: (userType: UserType) =>
            [
              UserType.SUPERUSER,
              UserType.PROVIDER,
              UserType.THERAPIST,
              UserType.CUSTOMER,
              UserType.CAM,
              UserType.SUPPORT,
            ].includes(userType),
          href: '/location/list',
          aliases: ['/organization', '/location'],
          children: [
            {
              label: 'Locations',
              filter: (userType: UserType) =>
                [UserType.SUPERUSER, UserType.CAM, UserType.SUPPORT].includes(
                  userType,
                ),
              href: '/location/list',
            },
            {
              label: 'Organizations',
              filter: (userType: UserType) =>
                [UserType.SUPERUSER, UserType.CAM, UserType.SUPPORT].includes(
                  userType,
                ),
              href: '/organization/list',
            },
          ],
        },
        {
          icon: 'calendar_month',
          label: 'Schedule',
          filter: (userType: UserType) =>
            [UserType.PROVIDER, UserType.THERAPIST].includes(userType),
          href: '/schedule',
          children: [
            {
              label: 'Calendar',
              filter: (userType: UserType) =>
                [UserType.PROVIDER, UserType.THERAPIST].includes(userType),
              href: '/schedule/calendar',
            },
            {
              label: 'Availability',
              filter: (userType: UserType) =>
                [UserType.PROVIDER, UserType.THERAPIST].includes(userType),
              href: '/availability',
            },
          ],
        },
        {
          icon: 'attach_money',
          label: 'Billing',
          filter: (userType: UserType) =>
            [UserType.PROVIDER, UserType.THERAPIST].includes(userType),
          href: '/billing',
        },
        {
          icon: 'home',
          label: 'Users',
          filter: (userType: UserType) =>
            [UserType.SUPERUSER, UserType.CAM, UserType.SUPPORT].includes(
              userType,
            ),
          href: '/users',
        },
        {
          icon: 'desktop_windows',
          label: 'Room',
          filter: (userType: UserType) =>
            [
              UserType.SUPERUSER,
              UserType.PROVIDER,
              UserType.THERAPIST,
              UserType.CAM,
              UserType.SUPPORT,
            ].includes(userType),
          href: this.plUrls.urls.roomFE,
          target: '_self',
        },
        {
          icon: 'local_library',
          label: 'Library',
          filter: (userType: UserType) =>
            [
              UserType.SUPERUSER,
              UserType.PROVIDER,
              UserType.THERAPIST,
              UserType.CAM,
              UserType.SUPPORT,
            ].includes(userType),
          href: this.plUrls.urls.libraryFE,
          target: '_self',
        },
      ]
        .filter(link => link.filter(this.userType))
        .map(link => ({
          ...link,
          children: link.children?.filter(child => child.filter(this.userType)),
        }));
    });
  }
}
