<!-- Note: this will be the new order of tabs in the task card component 
  so once the feature flag be removed this is the block that will remain 
  in the template  -->
<ng-container
  *ngIf="isHomePageTabsNewOrderEnabled$ | async; else legacyTabsTemplate"
>
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Due dates ({{ tasks?.dueDates?.length ?? 0 }})"
      ><div class="tasks">
        <ng-container *ngIf="tasks?.dueDates?.length < 1">
          <ng-container *ngTemplateOutlet="noTasks"></ng-container>
        </ng-container>
        <ng-container *ngFor="let task of tasks.dueDates">
          <ng-container
            *ngTemplateOutlet="taskTemplate; context: { $implicit: task }"
          ></ng-container>
        </ng-container></div
    ></mat-tab>
    <mat-tab
      label="Referrals & evaluations ({{
        tasks?.referralsAndEvaluations?.length ?? 0
      }})"
    >
      <div class="tasks">
        <ng-container *ngIf="tasks?.referralsAndEvaluations?.length < 1">
          <ng-container *ngTemplateOutlet="noTasks"></ng-container>
        </ng-container>
        <ng-container *ngFor="let task of tasks.referralsAndEvaluations">
          <ng-container
            *ngTemplateOutlet="taskTemplate; context: { $implicit: task }"
          ></ng-container>
        </ng-container></div
    ></mat-tab>
    <mat-tab label="Tasks ({{ tasks?.tasks?.length ?? 0 }})">
      <div class="tasks">
        <ng-container *ngIf="tasks?.tasks?.length < 1">
          <ng-container *ngTemplateOutlet="noTasks"></ng-container>
        </ng-container>
        <ng-container *ngFor="let task of tasks.tasks">
          <ng-container
            *ngTemplateOutlet="taskTemplate; context: { $implicit: task }"
          ></ng-container>
        </ng-container></div
    ></mat-tab>
  </mat-tab-group>
</ng-container>
<!-- ------------------------------------------------------------------------------ -->

<!-- TODO: Remove this block of code once the feature flag is removed -->
<ng-template #legacyTabsTemplate>
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Tasks ({{ tasks?.tasks?.length ?? 0 }})">
      <div class="tasks">
        <ng-container *ngIf="tasks?.tasks?.length < 1">
          <ng-container *ngTemplateOutlet="noTasks"></ng-container>
        </ng-container>
        <ng-container *ngFor="let task of tasks.tasks">
          <ng-container
            *ngTemplateOutlet="taskTemplate; context: { $implicit: task }"
          ></ng-container>
        </ng-container></div
    ></mat-tab>
    <mat-tab
      label="Referrals & evaluations ({{
        tasks?.referralsAndEvaluations?.length ?? 0
      }})"
    >
      <div class="tasks">
        <ng-container *ngIf="tasks?.referralsAndEvaluations?.length < 1">
          <ng-container *ngTemplateOutlet="noTasks"></ng-container>
        </ng-container>
        <ng-container *ngFor="let task of tasks.referralsAndEvaluations">
          <ng-container
            *ngTemplateOutlet="taskTemplate; context: { $implicit: task }"
          ></ng-container>
        </ng-container></div
    ></mat-tab>
    <mat-tab label="Due dates ({{ tasks?.dueDates?.length ?? 0 }})"
      ><div class="tasks">
        <ng-container *ngIf="tasks?.dueDates?.length < 1">
          <ng-container *ngTemplateOutlet="noTasks"></ng-container>
        </ng-container>
        <ng-container *ngFor="let task of tasks.dueDates">
          <ng-container
            *ngTemplateOutlet="taskTemplate; context: { $implicit: task }"
          ></ng-container>
        </ng-container></div
    ></mat-tab>
  </mat-tab-group>
</ng-template>
<!-- ------------------------------------------------------------------------------ -->
<ng-template #noTasks>
  <div class="no-tasks">
    <div class="no-tasks-header">No current tasks</div>
    <div class="no-tasks-body">
      New tasks and action items will be shown here for your review when ready
    </div>
    <div class="icon-checklist-container">
      <mat-icon svgIcon="checklist" class="icon-checklist"></mat-icon>
    </div>
  </div>
</ng-template>

<ng-template #taskTemplate let-task>
  <div class="task">
    <div class="header">
      <div class="content" [innerHTML]="task.message"></div>
      <div class="action">
        <a [href]="task.actionUrl">{{ task.actionLabel }}</a>
      </div>
    </div>
  </div>
</ng-template>
