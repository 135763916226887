<h2 *ngIf="!initialized" class="page-loading" style="text-align: center">
  Loading<br />
  <pl-dot-loader></pl-dot-loader>
</h2>
<div class="pl-assignment-manager hidden" [ngClass]="{ visible: initialized }">
  <pl-table-wrapper
    class="x-qa-table"
    (onQuery)="onQuery($event)"
    [stateName]="tableStateName"
  >
    <div class="col col1">
      <div class="sticky-container side" [style.z-index]="99">
        <div class="schoolyear-container">
          <pl-schoolyear-select
            [(selectedSchoolYear)]="selectedSchoolYear"
            [simpleSelect]="true"
            [useValueId]="true"
            (onYearSelected)="onChangeSchoolYear($event)"
          >
          </pl-schoolyear-select>
        </div>
        <div [hidden]="!filtersVisible" class="filters-container">
          <pl-table-filters-side
            [filters]="filters"
            [filtersVisible]="filtersVisible"
            [canCloseFilters]="false"
            [queryOnChanges]="true"
          ></pl-table-filters-side>
        </div>
      </div>
    </div>

    <div class="col col2">
      <div id="pageTop"></div>
      <div class="sticky-container">
        <div class="button-container">
          <div class="button-item">
            <pl-input-checkbox
              [(model)]="includeProposedInTotals"
              (click)="onClickTotalsType()"
              label="Include Proposed/Locked"
            ></pl-input-checkbox>
          </div>
          <div class="button-item">
            <pl-input-checkbox
              *ngIf="canToggleCapacityPlanning()"
              [(model)]="includeCapacityInTotals"
              (click)="onClickTotalsType()"
              label="Include Proposed Capacity Planning"
            ></pl-input-checkbox>
          </div>
          <button
            pl-button
            class="xqa-btn-refresh-page refresh-page nowrap"
            (click)="onClickRefreshPage()"
            [disabled]="buttonRefresh || loading"
          >
            <pl-icon
              [svg]="'rotate'"
              [scale]="0.6"
              [verticalAlign]="'-1px'"
              class="margin-small-r"
            ></pl-icon>
            Refresh
          </button>
        </div>
      </div>

      <div class="table-container" style="position: relative">
        <div class="closed-lost-banner">
          <pl-alert-banner
            type="error"
            *ngIf="showClosedLostAlertBanner"
            [title]="closedLostBannerTitle"
            buttonLabel="View opportunities"
            (buttonClicked)="viewOpportunitiesClicked()"
            (closeClicked)="showClosedLostAlertBanner = false"
            >Reassign providers assigned to and reserved for these
            assignments.</pl-alert-banner
          >
        </div>
        <pl-floating-loader
          *ngIf="!isDataLoaded || loading"
        ></pl-floating-loader>
        <div
          *ngIf="isDataLoaded && !loading && !orgDemandList.length"
          class="padding-xlarge-tb"
        >
          <h2 [style.text-align]="center">
            There are no assignments matching the filter criteria
          </h2>
        </div>
        <div
          *ngFor="let orgDemandItem of orgDemandList"
          class="org-container"
          [ngClass]="{ loading: loading }"
        >
          <div *ngIf="hasOpptyDemand(orgDemandItem)">
            <div class="table-container" style="position: relative">
              <pl-floating-loader
                *ngIf="!isDataLoaded || loading"
              ></pl-floating-loader>
              <div
                *ngIf="isDataLoaded && !loading && !orgDemandList.length"
                class="padding-xlarge-tb"
              >
                <h2 [style.text-align]="center">
                  There are no assignments matching the filter criteria
                </h2>
              </div>
              <pl-assignment-manager-org-header
                [orgDemandItem]="orgDemandItem"
              ></pl-assignment-manager-org-header>

              <div class="demands-container">
                <div *ngIf="hasOpptyDemand(orgDemandItem)" class="row-list">
                  <!-- Oppty Demands-->
                  <div
                    class="oppty-demand-item"
                    *ngFor="
                      let opptyDemandItem of orgDemandItem.opptyDemandList;
                      index as idx
                    "
                  >
                    <div>
                      <pl-demand-item-header
                        [orgDemandItem]="orgDemandItem"
                        [opptyDemandItem]="opptyDemandItem"
                        [demandNotes]="demandNotes"
                        [schoolYear]="selectedSchoolYear"
                        (addEdit)="
                          onClickAddEdit(null, opptyDemandItem, orgDemandItem)
                        "
                      ></pl-demand-item-header>
                      <pl-alert
                        *ngIf="!opptyDemandItem.providerSupplyList.length"
                      >
                        There are no providers assigned to this demand line.
                        <button
                          pl-button
                          (click)="
                            onClickAddEdit(null, opptyDemandItem, orgDemandItem)
                          "
                          class="x-qa-add-provider-in-alert inline link underlined uncolored"
                        >
                          Add provider
                        </button>
                      </pl-alert>
                      <pl-assignments-table
                        *ngIf="opptyDemandItem.providerSupplyList.length"
                        [assignmentList]="opptyDemandItem.providerSupplyList"
                        [orgName]="orgDemandItem.orgName"
                        (accept)="onClickApprove($event)"
                        (reject)="onClickReject($event)"
                        (addEdit)="
                          onClickAddEdit($event, opptyDemandItem, orgDemandItem)
                        "
                      ></pl-assignments-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <pl-table-footer
        (onPageChange)="onPageChange($event)"
        [hidden]="!orgDemandList.length"
        [total]="total"
        [currentPage]="currentPage"
        [currentPageForce]="currentPage"
        [useFixedPageSize]="useFixedPageSize"
        [pageSize]="pageSize"
        currentPageEnableForceUpdates="true"
        class="table-footer padding-small-l"
      ></pl-table-footer>
    </div>
  </pl-table-wrapper>
</div>
