import { DecimalPipe, DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { fadeInOnEnterAnimation } from 'angular-animations';

import { Option } from '@common/interfaces';
import { PLUtilService } from '@common/services';
import {
  PLAssignmentProposalRaw,
  PLOrgDemandItem,
  PLOpptyDemandItem,
  PLAssignmentProposalItem,
  PLAssignmentStatusEnum,
  PLRejectedReasonsEnum,
} from './pl-assignment-manager.model';
import { PLAssignmentManagerService } from './pl-assignment-manager.service';
import { PLAssignmentProposalItemService } from './pl-assignment-proposal-item.service';
import { InputFieldName } from '../../common/enums/field-name.enum';
import {
  FeatureFlagName,
  FeatureFlagsService,
} from '../../common/feature-flags';

interface ModelFormat {
  provider?: string;
  selectedServiceType?: string;
  status?: PLAssignmentStatusEnum;
  weeklyHours?: string;
  startDate?: string;
  endDate?: string;
  therapyType?: string;
  reason?: string;
  notes?: string;
  esy?: any;
  cemDemoReason?: string;
  blockAssignmentTimeReason?: string;
}

@Component({
  selector: 'pl-custom-assignment-modal',
  templateUrl: './pl-custom-assignment-modal.component.html',
  styleUrls: ['./pl-custom-assignment-modal.component.less'],
  providers: [DecimalPipe, DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOnEnterAnimation({ anchor: 'fadeIn', duration: 500 })],
})
export class PLCustomAssignmentModalComponent implements OnInit, OnDestroy {
  @Input() headerText: string;
  @Input() schoolYear: any;
  @Input() includePending = false;
  @Input() opptyDemandItem: PLOpptyDemandItem;
  @Input() orgDemandItem: PLOrgDemandItem;
  @Input() proposalItem: PLAssignmentProposalItem; // for edit mode
  @Input() serviceTypeOpts: Option[]; // slt, ot, etc
  @Input() organizationOpts: Option[];
  @Input() providerOpts: Option[];
  @Input() therapyTypeOpts: Option[]; // direct therapy, evaluation
  @Input() onCancel: Function;
  @Input() onSaveSuccess: Function;
  @Input() onSaveError: Function;
  @Input() canLockOrReserve: Function;

  model: ModelFormat = {};
  availableProviderOpts: Option[] = [];

  assignmentFormGroup: FormGroup = new FormGroup({});
  inFlight = false;
  rejectedReasonsOpts: Option[] = this.service.PL_REJECTED_REASONS_OPTS;
  removedReasonsOpts: Option[] = this.service.REMOVED_REASONS_OPTS;
  cemDemoReasonOpts: Option[] = this.service.CEM_DEMO_REASON_OPTS;
  declinedReasonsOpts: Option[] = this.service.PROVIDER_DECLINED_REASONS_OPTS;
  assignmentStatusOpts: Option[];
  saveErrors: any[] = [];
  checkChangesTimeout: any;
  providerOptsOrig: any;
  showNameSearch = false;
  allowLockOrReserve = true;
  proposeNonQualifiedProvider = false;
  nonQualifiedProviders: Option[] = [];
  loading = false;
  assignmentProvider: any = undefined;
  showLastWorkDayField = false;
  inputFieldName = InputFieldName;
  allowTentativeAssignment: boolean = false;

  // constrain the input to numbers between [ 1.0, 40.0 ], allowing only 1/4 hour increments
  // and allow for loosely formatted values, such as 1, 1., 1.0
  maxHoursPattern =
    '(^(([0-9]|[1,2,3]\\d)(\\.|\\.(0|25|5|75))?)$)|(^(40)(\\.|\\.(0))?$)';

  isCamDemoGroup = false;

  isCamDemoNotesDisplayed = false;

  CAM_DEMO_GROUP = 'Cam Demo';

  constructor(
    public util: PLUtilService,
    private service: PLAssignmentManagerService,
    private proposalItemService: PLAssignmentProposalItemService,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private featureFlagsService: FeatureFlagsService,
  ) {}

  ngOnInit() {
    this.providerOptsOrig = [];
    if (this.proposalItem) {
      const providerOptionData = this.providerOptsOrig.find(
        ({ uuid }) => uuid === this.proposalItem.providerUuid,
      );
      if (providerOptionData) {
        this.isCamDemoGroup = this.isCamDemoGroupAssigned(
          providerOptionData.groups,
        );
      }
    }
    this.featureFlagsService
      .isFeatureEnabled(FeatureFlagName.referralDrivenAvailability)
      .subscribe(enabled => {
        this.allowTentativeAssignment = enabled;
      });

    this.featureFlagsService
      .isFeatureEnabled(FeatureFlagName.showLastWorkDayField)
      .subscribe(enabled => {
        this.showLastWorkDayField = enabled;
        if (this.showLastWorkDayField) {
          if (this.opptyDemandItem.lastProviderBillingDate) {
            this.model.endDate = this.datePipe.transform(
              this.opptyDemandItem.lastProviderBillingDate,
              'YYYY-MM-dd',
            );
          } else {
            this.getLastcontractDate();

            if (this.proposalItem?.endDate) {
              this.model.endDate = this.datePipe.transform(
                this.proposalItem?.endDate,
                'YYYY-MM-dd',
              );
            }
          }
        }
      });
    this.allowLockOrReserve = this.canLockOrReserve(this.opptyDemandItem);

    if (this.proposalItem) {
      this.model.provider = this.proposalItem.providerUuid;
      this.model.weeklyHours = this.decimalPipe.transform(
        this.proposalItem.supplyHours,
        '1.1',
      );

      this.model.startDate = this.datePipe.transform(
        this.proposalItem.startDate,
        'YYYY-MM-dd',
      );
      // call V2 endpoint
      this.requestSingleProvider(this.proposalItem.providerUuid);

      const status = (this.model.status = this.proposalItem.statusCode);
      const allowedStatus = this.service.CAM_SETTABLE_STATUS;
      let opts = this.service.STATUS_TRANSITION_OPTS_MAP[
        this.model.status
      ].filter((opt: Option) => allowedStatus[`${opt.value}`]);
      if (!opts.find((opt: Option) => opt.value === status)) {
        opts.push({
          value: status,
          label: this.service.ASSIGNMENT_STATUS[status],
        });
      }

      // if we're proposed, and we can't lock or reserve, still allow proposed => rejected_by_pl
      if (
        status === PLAssignmentStatusEnum.PROPOSED &&
        !this.allowLockOrReserve
      ) {
        opts = opts.filter(
          (opt: any) =>
            opt.value === PLAssignmentStatusEnum.PROPOSED ||
            opt.value === PLAssignmentStatusEnum.PL_REJECTED,
        );
      }

      this.isCamDemoNotesDisplayed = this.isCamDemoNotesDisplayedOnEdit();

      if (this.proposalItem?.blockAssignmentTimeReason) {
        this.model.cemDemoReason = this.proposalItem?.cemDemoReason;
      }

      if (this.proposalItem?.blockAssignmentTimeReason) {
        this.model.blockAssignmentTimeReason =
          this.proposalItem?.blockAssignmentTimeReason;
      }

      this.assignmentStatusOpts = opts;
    }
    const fn = () => {
      this.checkChangesTimeout = setTimeout(() => {
        this.cdr.markForCheck();
        fn();
      }, 150);
    };
    fn();
  }

  updateProvidersPoolV2(
    allProviders: boolean = false,
    allProposed: boolean = false,
  ) {
    this.service
      .fetchProviderPoolV2(
        this.opptyDemandItem.uuid,
        Number(this.model.weeklyHours),
        this.model.startDate,
        this.model.endDate,
        allProviders ? 1 : 0,
        allProposed ? 1 : 0,
      )
      .subscribe((res: any) => {
        this.loading = false;
        const mapped = res.results.map((p: any) => {
          return {
            uuid: p.uuid,
            firstName: p.first_name,
            lastName: p.last_name,
            name: `${p.first_name} ${p.last_name}`,
            username: p.username,
            groups: p.groups,
            providerSubStatus: p.provider_sub_status,
            fitness: p.fitness,
            rank: p.rank,
            rankDescription: p.rank_description,
            removedReasons: p.removed_reasons,
            hasPendingReqs: p.has_pending_reqs,
            separationDate: p.separation_date,
            hasPendingSpecialty: p.has_pending_specialty,
            remainingHours: this.service.durationToDecimalHoursDecimal(
              p.remaining_hours_on_date,
            ),
          };
        });

        this.availableProviderOpts = mapped;
        this.providerOptsOrig = mapped;
        this.setAssignmentProvider(mapped);
      });
  }

  onDateChange(event: any, fieldName: string) {
    if (fieldName === InputFieldName.STARTDATE) {
      this.model.startDate = event.model;
    }

    if (fieldName === InputFieldName.ENDDATE) {
      this.model.endDate = event.model;
    }

    if (event.name) {
      this.assignmentFormGroup.controls[event.name].setValue(event.model);
    }

    // if we're in edit mode request all providers
    if (this.proposalItem && this.assignmentProvider)
      this.requestSingleProvider(this.assignmentProvider.uuid);
    else this.requestProviders(this.proposeNonQualifiedProvider);
  }

  onWeeklyHoursChange(event: any) {
    this.model.weeklyHours = event.target.value;

    // if we're in edit mode request all providers
    if (this.proposalItem && this.assignmentProvider)
      this.requestSingleProvider(this.assignmentProvider.uuid);
    else this.requestProviders(this.proposeNonQualifiedProvider);
  }

  onClickCancel() {
    this.onCancel();
  }

  canUpdateHours() {
    return (
      this.model.status !== PLAssignmentStatusEnum.PL_REJECTED &&
      this.model.status !== PLAssignmentStatusEnum.REMOVED
    );
  }

  onClickSave() {
    const reasons: any = {};
    if (this.model.reason) {
      switch (this.model.status) {
        case PLAssignmentStatusEnum.PL_REJECTED:
          reasons.pl_rejected_reason = this.model.reason;
          if (this.model.reason === PLRejectedReasonsEnum.OTHER) {
            reasons.pl_rejected_other_reason = this.model.notes;
          }
          break;
        case PLAssignmentStatusEnum.REMOVED:
          reasons.removed_reason = this.model.reason;
          if (this.model.reason === PLRejectedReasonsEnum.OTHER) {
            reasons.removed_other_reason = this.model.notes;
          }
      }
    }

    const hours = this.canUpdateHours()
      ? this.model.weeklyHours
      : this.proposalItem.supplyHours;
    const data: PLAssignmentProposalRaw = {
      school_year: this.schoolYear.id,
      demand: this.opptyDemandItem.uuid,
      user: this.model.provider,
      status: this.model.status,
      hours: this.service.decimalHoursToDuration(hours),
      start_date: this.model.startDate,
      cem_demo_reason: this.model?.cemDemoReason,
      block_assignment_time_reason:
        this.model?.blockAssignmentTimeReason || null,
      ...reasons,
      end_date: this.model.endDate,
    };
    if (this.proposalItem) {
      data.uuid = this.proposalItem.uuid;
    }
    if (this.includePending) {
      data.include_pending = true;
    }

    this.inFlight = true;

    // TODO: if editing and rejecting/removing, ignore changes to provider and hours
    // TODO: if editing and item is past proposal stage, ignore changes to provider and hours
    this.proposalItemService.saveProposal(data).subscribe(
      (res: any) => {
        this.util.log('--- save proposal SUCCESS', { data, res, STATE: this });
        this.onSaveSuccess(res, data);
        this.inFlight = false;
      },
      (err: any) => {
        let ERRORS: any[] = [];
        if (`${err.status}`.startsWith('50')) {
          ERRORS = [
            {
              key: 'System Error',
              text: 'Please contact support',
            },
          ];
        } else {
          for (const ERR in err.error) {
            ERRORS = [...ERRORS, { key: ERR, text: err.error[ERR] }];
          }
        }
        this.saveErrors = ERRORS;
        this.onSaveError(err, data);
        this.inFlight = false;
        this.util.log('--- save proposal ERROR', { data, err, STATE: this });
      },
    );
  }

  getNotesChars() {
    return this.service.getCharsLength(this.model.notes);
  }

  getCamDemoNotesChars() {
    return this.service.getCharsLength(this.model.blockAssignmentTimeReason);
  }

  isReasonOther() {
    return this.model.reason === PLRejectedReasonsEnum.OTHER;
  }

  isStatusRejectedPL() {
    return this.model.status === PLAssignmentStatusEnum.PL_REJECTED;
  }

  isStatusDeclinedByProvider() {
    return this.model.status === PLAssignmentStatusEnum.PROVIDER_REJECTED;
  }

  isStatusRemoved() {
    return this.model.status === PLAssignmentStatusEnum.REMOVED;
  }

  onQuery(info: { data: any }) {
    this.availableProviderOpts.sort((a: any, b: any) => {
      // number sorts
      if (info.data.orderKey === 'match') {
        const fitnessA = a.fitness || -99999;
        const fitnessB = b.fitness || -99999;

        return info.data.orderDirection === 'descending'
          ? fitnessA - fitnessB
          : fitnessB - fitnessA;
      }
      if (info.data.orderKey === 'hours') {
        return info.data.orderDirection === 'descending'
          ? a.remainingHours - b.remainingHours
          : b.remainingHours - a.remainingHours;
      }

      // string sorts
      let itemA;
      let itemB;
      if (info.data.orderKey === 'name') {
        itemA = a.name;
        itemB = b.name;
      } else if (info.data.orderKey === 'other') {
        itemA =
          a.isOnboarding +
          a.hasPendingReqs +
          a.removedReasons.substring(0, 3) +
          a.hasPendingSpecialty;
        itemB =
          b.isOnboarding +
          b.hasPendingReqs +
          b.removedReasons.substring(0, 3) +
          a.hasPendingSpecialty;
      } else {
        return;
      }

      return info.data.orderDirection === 'descending'
        ? itemB.localeCompare(itemA)
        : itemA.localeCompare(itemB);
    });
  }

  onSearchClick(event: any) {
    event.stopPropagation();
    this.showNameSearch = !this.showNameSearch;
  }

  onSearchInputClick(event: any) {
    event.stopPropagation();
  }

  onSearchInputChange(event: any) {
    const name = event.target.value.toLowerCase();
    if (name === '') {
      this.availableProviderOpts = this.providerOptsOrig;
    } else {
      this.availableProviderOpts = this.providerOptsOrig.filter((a: any) =>
        a.name.toLowerCase().includes(name),
      );
    }
  }

  onProviderSelected(groups: string[]): void {
    this.isCamDemoNotesDisplayed = this.isCamDemoGroupAssigned(groups);
  }

  onClickProposeNonQualifiedProvider(event: any) {
    this.proposeNonQualifiedProvider = !this.proposeNonQualifiedProvider;

    this.model.provider = null;
    this.model.status = this.proposeNonQualifiedProvider
      ? PLAssignmentStatusEnum.CAPACITY_PLANNING_LOCKED
      : null;

    this.requestProviders(this.proposeNonQualifiedProvider);
  }

  requestProviders(
    allProviders: boolean = false,
    includeProposed: boolean = false,
  ) {
    if (
      this.model.weeklyHours !== undefined &&
      this.model.weeklyHours !== '' &&
      this.model.startDate !== undefined &&
      this.model.startDate !== ''
    ) {
      this.loading = true;
      if (this.allowLockOrReserve && !allProviders) {
        this.updateProvidersPoolV2();
      } else {
        this.service
          .fetchProviderPoolV2(
            this.opptyDemandItem.uuid,
            Number(this.model.weeklyHours),
            this.model.startDate,
            this.model.endDate,
            allProviders ? 1 : 0,
            includeProposed ? 1 : 0,
          )
          .subscribe((res: any) => {
            if (allProviders) {
              this.setAssignmentProvider(res.results);
              this.handleNonQualifiedProvidersResponse({
                providers: res.results,
              });
            }
          });
      }
    }
  }

  // used in edit model where the assignment provider is already known
  requestSingleProvider(provider: string) {
    if (
      this.model.weeklyHours !== undefined &&
      this.model.weeklyHours !== '' &&
      this.model.startDate !== undefined &&
      this.model.startDate !== ''
    ) {
      this.service
        .fetchProviderPoolV2(
          this.opptyDemandItem.uuid,
          Number(this.model.weeklyHours),
          this.model.startDate,
          this.model.endDate,
          0,
          1,
          provider,
        )
        .subscribe((res: any) => {
          this.setAssignmentProvider(res.results);
          this.loading = false;
        });
    }
  }

  handleNonQualifiedProvidersResponse(pool: any) {
    this.loading = false;

    const mapped = pool.providers.map((p: any) => {
      const label = `${p.first_name} ${
        p.last_name
      } (${this.service.durationToDecimalHoursDecimal(
        p.remaining_hours ?? p.remaining_hours_on_date,
      )} hours remaining as of ${this.model.startDate})<br />`;

      return {
        label,
        value: p.uuid,
      };
    });

    this.nonQualifiedProviders = mapped;
  }

  isCamDemoGroupAssigned = (groups: string[]): boolean =>
    groups.some(group => group === this.CAM_DEMO_GROUP);

  isCamDemoNotesDisplayedOnEdit = () =>
    this.proposalItem && this.isCamDemoGroup;

  /**
   * Generate fallback option in case provider pool (/api/v1/provider-pool) does not return data
   * This won't display remaining hours
   * created to fix: PRO-195 https://presencelearning.atlassian.net/browse/PRO-195
   * @param proposalItem
   * @returns Option[]
   */
  buildProviderOptionFallback(
    proposalItem: PLAssignmentProposalItem,
  ): Option[] {
    const p = proposalItem.providerObj;
    return [
      {
        label: `<h3><a href="${this.service.getProviderDashboardUrl(
          proposalItem.providerUuid,
        )}" target="_blank">${p.first_name} ${p.last_name}</a></h3>`,
        value: proposalItem.providerUuid,
      },
    ];
  }

  /**
   * Checking provider options that comes from provider pool, this function filter data looking for
   * option that belongs to the current provider, if cannot find it builds a fallback option to avoid
   * displaying the UUID by default
   * @param providerOpts provider options
   * @returns Option[]
   */
  getProviderOptions(providerOpts: Option[]): Option[] {
    let opts: Option[] = [];
    if (providerOpts && providerOpts.length > 0) {
      let filteredProviderOpts = providerOpts.filter(
        (providerOption: Option) => {
          return providerOption.value === this.model.provider;
        },
      );
      if (filteredProviderOpts.length > 0) {
        opts = filteredProviderOpts;
      } else {
        opts = this.buildProviderOptionFallback(this.proposalItem);
      }
    } else {
      opts = this.buildProviderOptionFallback(this.proposalItem);
    }
    return opts;
  }

  ngOnDestroy() {
    clearInterval(this.checkChangesTimeout);
  }

  openProviderProfile(providerOpt) {
    const url = `/c/provider/${providerOpt.uuid}`;
    window.open(url, '_blank');
  }

  openSFDCRecord(providerOpt) {
    const url = `https://plearn.lightning.force.com/lightning/r/Contact/${providerOpt.salesforce_id}/view`;
    window.open(url, '_blank');
  }

  openMetabaseReport(providerOpt) {
    const url = this.service.getProviderDashboardUrl(providerOpt.uuid);
    window.open(url, '_blank');
  }

  getProviderDashboardUrl() {
    if (this.assignmentProvider !== undefined)
      return this.service.getProviderDashboardUrl(this.assignmentProvider.uuid);
  }

  setAssignmentProvider(pool) {
    if (!this.proposalItem) return;

    const assignmentProvider = pool.find(
      provider => provider.uuid === this.proposalItem.providerUuid,
    );

    if (assignmentProvider) {
      // normalize responses between V1 and V2 to remainingHours
      this.assignmentProvider = {
        ...assignmentProvider,
        remainingHours: this.service.durationToDecimalHoursDecimal(
          assignmentProvider.remaining_hours ??
            assignmentProvider.remaining_hours_on_date,
        ),
        firstName: assignmentProvider.first_name ?? '',
        lastName: assignmentProvider.last_name ?? '',
      };
    }
  }
  getLastcontractDate() {
    if (!this.model.endDate) {
      const contractDates = this.opptyDemandItem.contractDates.split(' to ');
      let lastContractDate = new Date(contractDates[1]);
      this.model.endDate = this.datePipe.transform(
        lastContractDate,
        'YYYY-MM-dd',
      );
    }
  }
}
