import { OnInit, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { FeatureFlagName, FeatureFlagsService } from '@common/feature-flags';
import { PLTasksService } from '@root/src/app/common/services';
@Component({
  selector: 'pl-task-card',
  templateUrl: './pl-task-card.component.html',
  styleUrls: ['./pl-task-card.component.less'],
})
export class PlTaskCardComponent implements OnInit {
  categories = {
    referral_conversion: 'referralsAndEvaluations',
    direct_service_undocumented: 'referralsAndEvaluations',
    unsigned_agreement: 'tasks',
    unsigned_w2_preagreement: 'tasks',
    intent_to_return: 'tasks',
    pending_assignment_preference: 'tasks',
    timesheet_reminder: 'dueDates',
    timesheet_reminder_need_documentation: 'dueDates',
    invoice_reminder: 'dueDates',
    invoice_reminder_need_documentation: 'dueDates',
    invoice_due_date_reminder: 'dueDates',
  };

  actionLabels = {
    referral_conversion: 'View',
    direct_service_undocumented: 'Document',
    unsigned_agreement: 'Sign',
    unsigned_w2_preagreement: 'Sign',
    intent_to_return: 'Take Survey',
    pending_assignment_preference: 'Feedback',
    timesheet_reminder: 'Timesheets',
    timesheet_reminder_need_documentation: 'Timesheets',
    invoice_reminder: 'Invoices',
    invoice_reminder_need_documentation: 'Invoices',
    invoice_due_date_reminder: 'Invoices',
  };

  tasks: Record<string, any[]> = {
    referralsAndEvaluations: [],
    tasks: [],
    dueDates: [],
  };
  loadingTasks = true;
  isHomePageTabsNewOrderEnabled$: Observable<boolean> =
    this.featureFlagService.isFeatureEnabled(
      FeatureFlagName.isHomePageTabsNewOrderEnabled,
    );
  constructor(
    private plTasksService: PLTasksService,
    private featureFlagService: FeatureFlagsService,
  ) {}

  groupTasks(tasks: any[]) {
    const groupedTasks = {};
    tasks.forEach(task => {
      task.actionLabel = this.actionLabels[task.taskType.code];
      const category = this.categories[task.taskType.code];
      if (!groupedTasks[category]) {
        groupedTasks[category] = [];
      }
      groupedTasks[category].push(task);
    });
    return groupedTasks;
  }

  ngOnInit() {
    this.plTasksService
      .getTasks()
      .pipe(first())
      .subscribe((res: any) => {
        this.tasks = this.groupTasks(res.tasks); // Assuming 'res' is the tasks array you want to assign

        this.loadingTasks = false;
      });
    this.plTasksService.refresh();
  }
}
