import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { Subject, forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

import { PLFadeInAnimation, PLFadeInOutAnimation } from '@common/animations';
import { Option } from '@common/interfaces';
import { PLUtilService } from '@common/services';
import { CurrentUserService } from '@modules/user/current-user.service';
import {
  PLModalService,
  PLConfirmDialogService,
  PLTableFrameworkService,
  PLTableFrameworkUrlService,
  PLTimezoneService,
} from '@root/index';

import { PageInfo } from '@root/src/lib-components/pl-table-framework/pl-table-framework.service';
import {
  PLOrgDemandItem,
  PLOpptyDemandItem,
  PLAssignmentProposalItem,
  PLAssignmentProposalRaw,
  StatusUpdateResults,
  PLAssignmentStatusEnum,
  OpportunityStageEnum,
} from './pl-assignment-manager.model';
import { PLAssignmentManagerService } from './pl-assignment-manager.service';
import { PLAssignmentProposalItemService } from './pl-assignment-proposal-item.service';
import { PLCustomAssignmentModalComponent } from './pl-custom-assignment-modal.component';
import {
  PLDemandNote,
  PLDemandNotesService,
} from './pl-demand-notes/pl-demand-notes.service';
import { PLRejectAssignmentModalComponent } from './pl-reject-assignments-modal.component';

import { PLUserService } from '../users/pl-user.service';
const TOAST_TIMEOUT = 10000;
@UntilDestroy()
@Component({
  selector: 'pl-assignment-manager',
  templateUrl: './pl-assignment-manager.component.html',
  styleUrls: ['./pl-assignment-manager.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [PLFadeInAnimation, PLFadeInOutAnimation],
})
export class PLAssignmentManagerComponent implements OnInit, OnDestroy {
  // data
  user: any;
  orgDemandList: PLOrgDemandItem[] = [];
  serviceTypeInfo: any;
  schoolYearInfo: any;
  organizationOpts: Option[];
  stateOpts: Option[];
  providers: any[];
  providerOpts: Option[];
  providerOptsWithType: Option[];
  camOpts: Option[];
  demandNotes: { [k: string]: PLDemandNote } = {};
  isDataLoaded = false;

  // model and state
  selectedSchoolYear: string;

  // track whether a query is in flight
  loading = true;
  lastQueryId: number;

  // if a query is in flight, track whether another query has been requested
  // e.g. if another filter has been applied
  reQueryRequestedWhileInFlight = false;

  initialized = false;
  lastQuery: any = {};
  filtersVisible = true;
  lastSelectedRow: {
    proposalItem: PLAssignmentProposalItem;
    orgDemandItem: PLOrgDemandItem;
    opptyDemandItem: PLOpptyDemandItem;
  };
  routeParams: any;

  // table framework related
  total: number;
  currentPage: number;
  pageSize = 10;
  useFixedPageSize = true;
  tableStateName = 'cam';

  showClosedLostAlertBanner = false;
  closedLostBannerTitle = 'There are Closed-Lost opportunities';

  // filter related
  private DEFAULT_MIN_PROBABILITY = 70;
  QUERY_SORT_DEFAULT = 'name';
  FilterKey = this.service.FilterKey;

  // other
  checkChangesTimeout: any;
  initialUrlParams: any;
  buttonRefresh = false;
  includeProposedInTotals = true;
  includeCapacityInTotals = false;
  stageOpts: Option[] = [];

  // statuses that correspond to assigned providers
  readonly assignedStatuses = [
    PLAssignmentStatusEnum.PENDING,
    PLAssignmentStatusEnum.ACTIVE,
    PLAssignmentStatusEnum.INITIATED,
    PLAssignmentStatusEnum.RESERVED,
    PLAssignmentStatusEnum.LOCKED,
    PLAssignmentStatusEnum.CAPACITY_PLANNING_LOCKED,
  ];

  defaultOrgFilters = [
    { value: this.FilterKey.MY_PROVIDERS, label: 'My Providers Only' },
    {
      value: this.FilterKey.UNFILLED_ACCOUNTS,
      label: 'Needs Attention Only',
    },
    {
      value: this.FilterKey.CURRENT_DEMANDS,
      label: 'Current Demands Only',
    },
  ];

  filtersMap: any = {
    [this.FilterKey.ORGANIZATION]: {
      label: 'Organizations',
      value: this.FilterKey.ORGANIZATION,
      selectOptsMulti: [],
    },
    [this.FilterKey.STATE]: {
      label: 'State',
      value: this.FilterKey.STATE,
      selectOptsMulti: [],
    },
    [this.FilterKey.STAGE]: {
      label: 'Stage',
      value: this.FilterKey.STAGE,
      selectOptsMulti: [],
    },
    [this.FilterKey.PROBABILITY]: {
      label: 'Minimum Probability',
      value: this.FilterKey.PROBABILITY,
      selectOptsBigFilter: this.service.PROBABILITIES_OPTS,
    },
    [this.FilterKey.PROBABILITY_MAX]: {
      label: 'Maximum Probability',
      value: this.FilterKey.PROBABILITY_MAX,
      selectOptsBigFilter: this.service.PROBABILITIES_MAX_OPTS,
    },
    [this.FilterKey.START_DATE_GTE]: {
      label: 'Start Date Between',
      value: this.FilterKey.START_DATE_GTE,
      datepicker: true,
    },
    [this.FilterKey.START_DATE_LT]: {
      value: this.FilterKey.START_DATE_LT,
      datepicker: true,
    },
    [this.FilterKey.SERVICE_TYPE]: {
      label: 'Service Types',
      value: this.FilterKey.SERVICE_TYPE,
      selectOptsMulti: [],
    },
    [this.FilterKey.DEMAND_TYPE]: {
      label: 'Demand Types',
      value: this.FilterKey.DEMAND_TYPE,
      selectOptsMulti: this.service.DEMAND_TYPE_OPTS,
    },
    [this.FilterKey.DISTRICT_TYPE]: {
      label: 'District Types',
      value: this.FilterKey.DISTRICT_TYPE,
      selectOptsMulti: this.service.DISTRICT_TYPE_OPTS,
    },
    [this.FilterKey.STATUS]: {
      label: 'Assignment Status',
      value: this.FilterKey.STATUS,
      selectOptsMulti: this.service.ASSIGNMENT_STATUS_OPTS,
    },
    [this.FilterKey.PROVIDER]: {
      label: 'Provider',
      value: this.FilterKey.PROVIDER,
      selectOptsBigFilter: [],
    },
    [this.FilterKey.CAM]: {
      label: 'Empowerment Manager',
      value: this.FilterKey.CAM,
      selectOptsBigFilter: [],
    },
    [this.FilterKey.ORG_FILTERS]: {
      value: this.FilterKey.ORG_FILTERS,
      optionWidth: '200px',
      selectOptsCheckbox: this.defaultOrgFilters,
    },
  };

  filters: any[] = [
    this.filtersMap[this.FilterKey.ORG_FILTERS],
    this.filtersMap[this.FilterKey.ORGANIZATION],
    this.filtersMap[this.FilterKey.STATE],
    this.filtersMap[this.FilterKey.STAGE],
    this.filtersMap[this.FilterKey.PROBABILITY],
    this.filtersMap[this.FilterKey.PROBABILITY_MAX],
    this.filtersMap[this.FilterKey.START_DATE_GTE],
    this.filtersMap[this.FilterKey.START_DATE_LT],
    this.filtersMap[this.FilterKey.SERVICE_TYPE],
    this.filtersMap[this.FilterKey.DEMAND_TYPE],
    this.filtersMap[this.FilterKey.DISTRICT_TYPE],
    this.filtersMap[this.FilterKey.STATUS],
    this.filtersMap[this.FilterKey.PROVIDER],
    this.filtersMap[this.FilterKey.CAM],
  ];

  destroyed$ = new Subject<boolean>();

  constructor(
    public util: PLUtilService,
    private toastr: ToastrService,
    private plModal: PLModalService,
    private plConfirm: PLConfirmDialogService,
    private service: PLAssignmentManagerService,
    private proposalItemService: PLAssignmentProposalItemService,
    private tableService: PLTableFrameworkService,
    private tableUrlService: PLTableFrameworkUrlService,
    private currentUserService: CurrentUserService,
    private cdr: ChangeDetectorRef,
    private plTimezoneSvc: PLTimezoneService,
    private plUserService: PLUserService,
    private plDemandNotesService: PLDemandNotesService,
    private overlay: Overlay,
  ) {}

  ngOnInit() {
    this.initStates();
    this.initStages();

    forkJoin([
      this.initUserObservable(),
      this.initOrganizationsObservable(),
      this.initServiceTypesObservable(),
      this.initProvidersObservable(),
      this.initSchoolYearsObservable(),
      this.initCamsListObservable(),
    ]).subscribe(
      ([
        user,
        orgsResult,
        serviceTypesResult,
        providersResult,
        schoolYearsResult,
        camsListResult,
      ]: [any, any, any, any, any, any]) => {
        // getStateFromUrl() does not work in forkJoin
        this.tableUrlService
          .getStateFromUrl(this.tableStateName)
          .subscribe((res: any) => {
            this.initialUrlParams = res.query;
            this.util.log('initial url params', {
              urlParams: this.initialUrlParams,
              STATE: this,
            });
            if (
              localStorage.getItem('PL_DEBUG_QUERY') &&
              this.initialUrlParams.limit
            ) {
              this.pageSize = Number(this.initialUrlParams.limit);
            }

            this.initFilters(user);
            this.initUser(user);
            this.initOrganizations(orgsResult);
            this.initServiceTypes(serviceTypesResult);
            this.initProviders(providersResult);
            this.initSchoolYears(schoolYearsResult);
            this.initCamsList(camsListResult);

            this.isDataLoaded = true;
          });
      },
    );

    const fn = () => {
      this.checkChangesTimeout = setTimeout(() => {
        this.cdr.markForCheck();
        fn();
      }, 150);
    };
    fn();
  }
  viewOpportunitiesClicked() {
    this.filtersMap[this.FilterKey.STAGE].textArray = [
      OpportunityStageEnum.CLOSED_LOST,
    ];
    this.filtersMap[this.FilterKey.STATUS].textArray = this.assignedStatuses;
    this.updateFilters();
  }

  initFilters(user: any) {
    if (user.is_superuser) {
      this.filtersMap[this.FilterKey.ORG_FILTERS].selectOptsCheckbox = [
        ...this.defaultOrgFilters,
        {
          value: this.FilterKey.INCLUDE_DEMO_DEMANDS,
          label: 'Include Demo Demands',
        },
      ];
    }

    const params = this.initialUrlParams;
    if (!params.INIT) {
      this.filtersMap[this.FilterKey.PROBABILITY].text =
        this.DEFAULT_MIN_PROBABILITY;
      this.updateFilters();
    } else {
      this.initFiltersFromUrlParams(params);
    }
  }

  initFiltersFromUrlParams(params: any) {
    for (const key in params) {
      if (this.filtersMap[key] && params[key]) {
        this.filtersMap[key].textArray = params[key].split(',');
      }
    }
    this.updateFilters();
  }

  initUserObservable() {
    return this.currentUserService.getCurrentUser().pipe(first());
  }

  initUser(user: any) {
    this.user = user;
  }

  initServiceTypesObservable() {
    return this.service.fetchServiceTypes();
  }

  initServiceTypes(res: any) {
    this.serviceTypeInfo = this.service.buildServiceTypeInfo(res);
    this.filtersMap[this.FilterKey.SERVICE_TYPE].selectOptsMulti =
      this.serviceTypeInfo.opts;
  }

  initOrganizationsObservable() {
    return this.service.fetchOrganizations({ orderBy: 'name' });
  }

  initOrganizations(res: any) {
    this.organizationOpts = this.service.buildOrganizationOpts(res);
    this.filtersMap[this.FilterKey.ORGANIZATION].selectOptsMulti =
      this.organizationOpts;
  }

  initStates() {
    this.stateOpts = this.service.fetchStates();
    this.filtersMap[this.FilterKey.STATE].selectOptsMulti = this.stateOpts;
  }

  initStages() {
    this.stageOpts = Object.values(OpportunityStageEnum).map(stageType => ({
      value: stageType,
      label: stageType,
    }));
    this.filtersMap[this.FilterKey.STAGE].selectOptsMulti = this.stageOpts;
  }

  initProvidersObservable() {
    const providers$ = this.service.fetchProviders();

    return forkJoin([providers$]).pipe(first());
  }

  initProviders(res: any) {
    const providers = (this.providers = res[0]);
    this.providerOpts = this.service.buildProviderOpts(providers);
    this.filtersMap[this.FilterKey.PROVIDER].selectOptsBigFilter =
      this.providerOpts;
  }

  initSchoolYearsObservable() {
    return this.service.fetchSchoolYearsInfo();
  }

  initSchoolYears(res: any) {
    this.schoolYearInfo = res;
    const params = this.initialUrlParams;
    const sy = params.school_year;
    if (sy) {
      this.selectedSchoolYear = sy;
    } else {
      this.selectedSchoolYear = res.currentSchoolYear.id;
    }
  }

  initCamsListObservable() {
    const params = {
      group__in: 'Clinical Account Manager',
      is_active: true,
      limit: 100,
    };

    return this.plUserService.getUsersOnce(params);
  }

  initCamsList(results: any) {
    this.camOpts = results.users.map((user: any) => ({
      label: `${user.user.firstName} ${user.user.lastName}`,
      value: user.user.id,
    }));
    this.filtersMap[this.FilterKey.CAM].selectOptsBigFilter = this.camOpts;
  }

  onQuery(inParams: { query: any }) {
    // ignore until we're ready
    if (!this.isDataLoaded) return;

    // Prepare values to store as "lastQuery"
    const lastQuery = { ...this.lastQuery };
    const params = (this.lastQuery = inParams.query);

    if (!params.orderBy) {
      params.orderBy = this.QUERY_SORT_DEFAULT;
    }
    const init = this.initialUrlParams.INIT || lastQuery.INIT;
    params.INIT = 1;

    // Any filter action should reset to page 1
    // SY is a custom filter and requires this treatment
    if (this.selectedSchoolYear !== lastQuery.school_year) {
      this.currentPage = params.page = 1;
    }

    params.school_year = this.selectedSchoolYear;

    // If something other than the page changed, go to page 1. This should
    // happened automatically when a filter is changed, but... isn't.
    if (!this.isPageChangeOnly(params, lastQuery)) {
      this.currentPage = params.page = 1;
      params.offset = 0;
    }

    // build up query for /demand
    let query = {
      ...params,
    };

    // handle param defaults and transformations
    if (!init) {
      query.limit = params['limit'] = this.pageSize;
    } else {
      const orgFilters = params[this.FilterKey.ORG_FILTERS];

      if (orgFilters) {
        if (orgFilters.indexOf(this.FilterKey.MY_PROVIDERS) > -1) {
          query.providers_cam = this.user.uuid;
        }
        if (orgFilters.indexOf(this.FilterKey.UNFILLED_ACCOUNTS) > -1) {
          query.unmet_demand = true;
        }
        if (orgFilters.indexOf(this.FilterKey.CURRENT_DEMANDS) > -1) {
          query.exclude_expired = true;
        }
        if (orgFilters.indexOf(this.FilterKey.INCLUDE_DEMO_DEMANDS) > -1) {
          query.include_demo = true;
        }
      }
    }

    // to prevent out of order data, block requests while in flight.
    if (this.initialized && this.loading) {
      this.reQueryRequestedWhileInFlight = true;
      this.util.log(`re-query blocked... while in flight`);
      return;
    }

    // update url
    const queryParams = this.tableService.getQueryParams(params);
    this.tableUrlService.updateUrl(this.tableStateName, queryParams);

    // finalize query for /demand
    query = (({ INIT, orgFilters_In, ...r } = query) => r)();

    // scroll to the top of the view to see new data
    document
      .querySelectorAll('#pageTop')[0]
      .scrollIntoView({ behavior: 'auto', block: 'end' });

    // timing
    const start = new Date().getTime();

    this.loading = true;
    const queryId = (this.lastQueryId = Math.round(Math.random() * 1000));
    this.pageSize = query.limit || this.pageSize;
    this.util.log(`start query`, { __queryId: queryId });

    this.updateClosedLostCount(query);

    // TODO: change this to use a long lived observable for better control flow
    this.service.fetchDemand(query).subscribe(
      (res: any) => {
        this.loading = false;

        // timing
        const elapsed = Math.round(new Date().getTime() - start);

        // Single-flight serialization (not queued)
        if (this.reQueryRequestedWhileInFlight) {
          this.util.log(`re-query requested after ${elapsed}ms`);
          this.reQueryRequestedWhileInFlight = false;
          this.onQuery({ query: this.lastQuery });
          return;
        }

        // NOTE: if single-flight (above) is removed, make sure the last concurrent query wins.
        if (queryId !== this.lastQueryId) {
          this.util.log(`multi-flight detected... skipping`, {
            __queryId: queryId,
            _params: params,
            _query: query,
          });
          return;
        }
        this.total = res.count;

        this.plDemandNotesService
          .getNotesBatch(this.getDemandIds(res))
          .subscribe((demandNotes: { [k: string]: PLDemandNote }) => {
            this.demandNotes = demandNotes;
          });
        this.orgDemandList = this.service.buildOrgDemandList(
          res.results,
          this.includeProposedInTotals,
          this.includeCapacityInTotals,
          this.lastQuery.status,
          this.plTimezoneSvc,
          this.user.timezone,
        );
        this.initialized = true;

        this.util.log(`end query`, {
          __queryId: queryId,
          _elapsed: elapsed,
          _params: params,
          _query: query,
          orgDemandList: this.orgDemandList,
          orgDemandRaw: res.results,
          STATE: this,
        });
      },
      (err: any) => {
        this.util.elog('/demand onQuery ERROR', {
          err,
          params,
          query,
          STATE: this,
        });
        this.loading = false;
      },
    );
  }

  /**
   * Compare the newQuery and lastQuery. If the number of params is different
   * then it's not only a page change. If they have the same keys and keys that
   * are not page or offset changed then it's not only a page change.
   * @param newQuery C
   * @param lastQuery
   * @returns
   */
  isPageChangeOnly(newQuery: any, lastQuery: any) {
    const newQueryKeys = Object.keys(newQuery);
    const lastQueryKeys = Object.keys(lastQuery);
    if (newQueryKeys.length !== lastQueryKeys.length) {
      return false;
    }
    return !newQueryKeys.some(
      key =>
        key !== 'page' && key !== 'offset' && newQuery[key] !== lastQuery[key],
    );
  }

  /**
   * Query for all demands in Closed Lost stage. Then count the number of demands
   * that have at least one provider assigned. Set closedLostCount to this value
   * and update the banner title and display toggle accordingly.
   *
   * @param outerQuery The query object for the current demand list. Used to get
   * the school year, we may further refine this query in the future.
   */
  private updateClosedLostCount(outerQuery: any) {
    const query = { stage: 'Closed lost', school_year: outerQuery.school_year };
    this.service.fetchDemand(query).subscribe((res: any) => {
      const closedLostOrgDemandList = this.service.buildOrgDemandList(
        res.results,
        false,
        false,
        '',
        this.plTimezoneSvc,
        this.user.timezone,
      );
      let closedLostCount = 0;
      closedLostOrgDemandList.forEach(orgDemandItem => {
        const found = orgDemandItem.opptyDemandList.filter(opptyDemandItem => {
          const providersNeedingAction =
            opptyDemandItem.providerSupplyList.filter(providerSupplyItem => {
              return this.assignedStatuses.includes(
                providerSupplyItem.statusCode,
              );
            });
          return providersNeedingAction.length;
        });
        closedLostCount += found.length;
      });
      if (closedLostCount > 0) {
        this.showClosedLostAlertBanner = true;
        this.closedLostBannerTitle =
          closedLostCount === 1
            ? `There is 1 Closed-Lost opportunity`
            : `There are ${closedLostCount} Closed-Lost opportunities`;
      } else {
        this.showClosedLostAlertBanner = false;
      }
    });
  }

  onClickApprove(item: PLAssignmentProposalItem) {
    const APPROVED = PLAssignmentStatusEnum.RESERVED;
    this.proposalItemService
      .updateProposal(this.orgDemandList, item, APPROVED)
      .pipe(first())
      .subscribe((res: StatusUpdateResults) => {
        this.util.log('approved proposals', { res, STATE: this });
        this.service.toastStatusResults(res);

        this.updateFulfillmentTotals();
      });
  }

  onClickReject(item: PLAssignmentProposalItem) {
    let modalRef: any;
    // Reject UI is constrained to 1 proposal
    const camProposal = item;
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      panelClass: 'custom-overlay-panel',
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });

    const overlayRef: OverlayRef = this.overlay.create(overlayConfig);
    const componentRef = overlayRef.attach(
      new ComponentPortal(PLRejectAssignmentModalComponent),
    );

    let providerAssignment = this.orgDemandList.find(orgDemand =>
      orgDemand.opptyDemandList.some(
        opptyDemand => opptyDemand.uuid === item.opptyDemandUuid,
      ),
    );

    const params: any = {
      camProposal,
      orgDemandList: this.orgDemandList,
      rejectedReasonsOpts: this.service.PL_REJECTED_REASONS_OPTS,
      providerAssignment,
      onCancel: () => {
        overlayRef.dispose();
      },
      onSaveSuccess: () => {
        this.toastr.success(`Successfully declined assignment`, '🎉 SUCCESS', {
          positionClass: 'toast-bottom-right',
          timeOut: TOAST_TIMEOUT,
        });
        overlayRef.dispose();
        this.updateFulfillmentTotals();
      },
      onSaveError: () => {
        this.toastr.error(`Unable to decline assignment`, '❌ ERROR', {
          positionClass: 'toast-bottom-right',
          timeOut: TOAST_TIMEOUT,
        });
      },
    };

    componentRef.instance.camProposal = params.camProposal;
    componentRef.instance.providerAssignment = params.providerAssignment;
    componentRef.instance.orgDemandList = params.orgDemandList;
    componentRef.instance.rejectedReasonsOpts = params.rejectedReasonsOpts;
    componentRef.instance.onCancel = params.onCancel;
    componentRef.instance.onSaveSuccess = params.onSaveSuccess;
    componentRef.instance.onSaveError = params.onSaveError;
  }

  onClickAddEdit(
    proposalItem: PLAssignmentProposalItem,
    opptyDemandItem: PLOpptyDemandItem,
    orgDemandItem: PLOrgDemandItem,
  ) {
    this.util.log('-- item', {
      proposalItem,
      opptyDemandItem,
      orgDemandItem,
      STATE: this,
    });
    let modalRef: any;

    let includePending = this.includeCapacityInTotals;
    let ignoreQualifications = false;
    let ignoreAvailability = false;
    const setIsReturningPropertyOnProvider = providerPool => {
      providerPool.map(provider => {
        const providerUuid = provider.uuid;
        const providerSupplyList = opptyDemandItem.providerSupplyList;
        const providerSupplyItem = providerSupplyList.find(
          assignmentProposalItem =>
            assignmentProposalItem.providerUuid === providerUuid,
        );

        provider.provider = providerSupplyItem
          ? providerSupplyItem.provider
          : '';

        // NOTE: Return reasonable safe default should providerSupplyItem not exist...
        provider.isReturning = providerSupplyItem
          ? providerSupplyItem.isReturning
          : false;

        provider.providerPreferences = providerSupplyItem
          ? providerSupplyItem.providerPreferences
          : [];

        provider.organizationPreferences = providerSupplyItem
          ? providerSupplyItem.organizationPreferences
          : [];
      });
    };

    if (proposalItem) {
      // This parameters will only apply when modal is in editting mode
      // https://presencelearning.atlassian.net/browse/PRO-205?focusedCommentId=51450
      includePending = true;
      ignoreQualifications = true;
      ignoreAvailability = true;
    }

    this.loading = true;

    this.service
      .fetchProviderPool(
        opptyDemandItem.uuid,
        includePending,
        ignoreQualifications,
        ignoreAvailability,
      )
      .subscribe((pool: any) => {
        this.loading = false;
        this.providerOptsWithType = this.service.buildProviderOptsWithType(
          pool.providers,
        );

        this.util.log('providerPool', {
          pool,
          providerOpts: this.providerOptsWithType,
          STATE: this,
        });
        if (!pool.providers.length && !proposalItem) {
          this.plConfirm.show({
            header: 'Provider List',
            content: `No eligible providers available`,
            primaryLabel: 'OK',
            primaryCallback: () => {
              this.plConfirm.hide();
            },
          });
          return;
        }
        const providerPoolOpts = pool.providers.map((p: any) =>
          this.providerOptsWithType.find((po: any) => p.uuid === po.value),
        );

        providerPoolOpts.forEach((option: any) => {
          const optionProvider = this.providers.find(
            provider => option.uuid === provider.user,
          );
          option.salesforce_id = optionProvider?.salesforce_id;
        });

        setIsReturningPropertyOnProvider(providerPoolOpts);

        const params: any = {
          opptyDemandItem,
          orgDemandItem,
          includePending,
          proposalItem, // for edit mode
          zIndex: 999,
          headerText: `${proposalItem ? 'Edit' : 'Add'} Assignment`,
          organizationOpts: this.organizationOpts,
          providerOpts: providerPoolOpts,
          schoolYear: this.schoolYearInfo.schoolYears.find((item: any) => {
            return item.id === this.selectedSchoolYear;
          }),
          therapyTypeOpts: [
            { label: 'Direct Therapy', value: 1 },
            { label: 'Evaluation', value: 2 },
          ],
          isBackgroundClickDisabled: true,
          onCancel: () => {
            modalRef.instance.destroy();
          },
          onSaveSuccess: (res: PLAssignmentProposalRaw, data: any) => {
            this.util.log('onSave', { res, data, STATE: this });
            const supplyItem: PLAssignmentProposalItem =
              this.service.buildProviderSupplyItem(
                opptyDemandItem.uuid,
                res,
                this.plTimezoneSvc,
                this.user.timezone,
                opptyDemandItem.pendingCompleteDate,
              );

            this.proposalItemService.upsertProviderSupplyItem(
              supplyItem,
              opptyDemandItem,
            );
            this.proposalItemService.updateOrgDemandTallies(
              orgDemandItem,
              opptyDemandItem.uuid,
            );
            this.updateFulfillmentTotals();

            // mutation issue here, need to clone the array to get pl-assignments-table to reflect the update
            opptyDemandItem.providerSupplyList = [
              ...opptyDemandItem.providerSupplyList,
            ];

            const text = proposalItem ? 'Updated' : 'Created new';
            this.toastr.success(
              `${text} assignment for<br/><b>${supplyItem.provider}</b>`,
              '🎉 SUCCESS',
              {
                positionClass: 'toast-bottom-right',
                timeOut: TOAST_TIMEOUT,
                enableHtml: true,
              },
            );
            modalRef.instance.destroy();
          },
          onSaveError: (err: any, data: any) => {
            this.util.errorLog('onSave', { err, data, STATE: this });
          },
          canLockOrReserve: this.canLockOrReserve.bind(this),
        };
        this.plModal
          .create(PLCustomAssignmentModalComponent, params)
          .subscribe((ref: any) => {
            modalRef = ref;
          });
      });
  }

  onClickTotalsType() {
    setTimeout(() => {
      this.updateFulfillmentTotals();
    });
  }

  canToggleCapacityPlanning() {
    if (
      this.lastQuery.status &&
      !this.lastQuery.status.includes(PLAssignmentStatusEnum.CAPACITY_PLANNING)
    ) {
      return false;
    }

    return true;
  }

  onClickRefreshPage() {
    this.buttonRefresh = true;
    setTimeout(() => {
      this.buttonRefresh = false;
      this.onQuery({ query: this.lastQuery });
    }, 500);
  }

  onChangeSchoolYear(event: any) {
    if (event.model === this.lastQuery.school_year) {
      return;
    }
    this.util.log('onChangeSchoolYear', { event, STATE: this });
    this.onQuery({ query: this.lastQuery });
  }

  hasOpptyDemand(orgDemandItem: PLOrgDemandItem) {
    return orgDemandItem.opptyDemandList.length;
  }

  canLockOrReserve(opptyDemandItem: PLOpptyDemandItem) {
    return (
      opptyDemandItem.probability >= MIN_PROBABILITY ||
      this.user.groups.includes('Super CAM')
    );
  }

  updateFilters() {
    this.filters = [
      this.filtersMap[this.FilterKey.ORG_FILTERS],
      this.filtersMap[this.FilterKey.ORGANIZATION],
      this.filtersMap[this.FilterKey.STATE],
      this.filtersMap[this.FilterKey.STAGE],
      this.filtersMap[this.FilterKey.PROBABILITY],
      this.filtersMap[this.FilterKey.PROBABILITY_MAX],
      this.filtersMap[this.FilterKey.START_DATE_GTE],
      this.filtersMap[this.FilterKey.START_DATE_LT],
      this.filtersMap[this.FilterKey.SERVICE_TYPE],
      this.filtersMap[this.FilterKey.DEMAND_TYPE],
      this.filtersMap[this.FilterKey.DISTRICT_TYPE],
      this.filtersMap[this.FilterKey.STATUS],
      this.filtersMap[this.FilterKey.PROVIDER],
      this.filtersMap[this.FilterKey.CAM],
    ];
  }

  onPageChange(pageInfo: PageInfo) {
    this.currentPage = pageInfo.currentPage;
    this.pageSize = pageInfo.pageSize;
  }

  // --------------------------
  // ------ PRIVATE METHODS
  // --------------------------

  private updateFulfillmentTotals() {
    this.orgDemandList.forEach((d: PLOrgDemandItem) => {
      this.proposalItemService.setOrgDemandItemSupplyTotal(
        d,
        this.includeProposedInTotals,
        this.includeCapacityInTotals,
      );
    });
  }

  private getDemandIds(response: {
    results: Array<{ demands: Array<{ uuid: string }> }>;
  }): string[] {
    let demand_uuids: string[] = [];
    response.results.forEach(({ demands }) => {
      demands.forEach(({ uuid }) => {
        demand_uuids.push(uuid);
      });
    });
    return demand_uuids;
  }

  ngOnDestroy() {
    clearTimeout(this.checkChangesTimeout);
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

const MIN_PROBABILITY = 100;

interface FilterTextArrayItem {
  textArray?: string[];
}
interface FilterTextItem {
  text?: string;
}
export interface PLAssignmentFilterValuesInterface {
  orgFiltersTextArray?: FilterTextArrayItem;
  organizationsTextArray?: FilterTextArrayItem;
  serviceTypeTextArray?: FilterTextArrayItem;
  assignmentStatusTextArray?: FilterTextArrayItem;
  providerText?: FilterTextItem;
}
