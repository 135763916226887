import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

import { PLSchoolYearsService } from '@common/services/';
import { User } from '@modules/user/user.model';

@Component({
  selector: 'pl-cam-dashboard',
  templateUrl: './pl-cam-dashboard.component.html',
  styleUrls: ['./pl-cam-dashboard.component.less'],
})
export class PLCamDashboardComponent implements OnInit {
  currentUser: User;
  selectedSchoolYearCode: string;
  selectedSchoolYear: any;

  constructor(
    private route: ActivatedRoute,
    private schoolYearService: PLSchoolYearsService,
  ) {}

  ngOnInit(): void {
    forkJoin(
      this.route.data.pipe(first()),
      this.schoolYearService.getCurrentSchoolYear().pipe(first()),
    ).subscribe(([data, schoolYear]: [{ currentUser: User }, any]) => {
      this.selectedSchoolYearCode = schoolYear.code;
      this.selectedSchoolYear = this.schoolYearService.getYearForCode(
        schoolYear.code,
      );
      this.currentUser = data.currentUser;
    });
  }

  handleSelectedSchoolYearChange(schoolYearCode: string): void {
    this.selectedSchoolYearCode = schoolYearCode;
    this.selectedSchoolYear =
      this.schoolYearService.getYearForCode(schoolYearCode);
  }

  isLoaded(): boolean {
    return !!(this.selectedSchoolYearCode && this.currentUser);
  }
}
