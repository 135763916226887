import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PLAssignmentRequirement } from '@common/assigment-machine/models';
import {
  PLAssignmentProposalItem,
  PLAssignmentStatusEnum,
} from '../pl-assignment-manager.model';
import { PLAssignmentManagerService } from '../pl-assignment-manager.service';

@Component({
  selector: 'pl-assignments-table',
  templateUrl: './pl-assignments-table.component.html',
  styleUrls: ['./pl-assignments-table.component.less'],
})
export class PLAssignmentsTableComponent {
  @Input() assignmentList: PLAssignmentProposalItem[];
  @Input() orgName: string;

  @Output() addEdit: EventEmitter<any> = new EventEmitter();
  @Output() accept: EventEmitter<PLAssignmentProposalItem> = new EventEmitter();
  @Output() reject: EventEmitter<PLAssignmentProposalItem> = new EventEmitter();

  displayedColumns: string[] = [
    'provider',
    'hours',
    'first-work-day',
    'missing-qualifications',
    'status',
  ];

  readonly editableStatuses = [
    PLAssignmentStatusEnum.PROPOSED,
    PLAssignmentStatusEnum.LOCKED,
    PLAssignmentStatusEnum.PENDING,
    PLAssignmentStatusEnum.INITIATED,
    PLAssignmentStatusEnum.ACTIVE,
    PLAssignmentStatusEnum.TENTATIVE,
    PLAssignmentStatusEnum.RESERVED,
    PLAssignmentStatusEnum.CAPACITY_PLANNING,
    PLAssignmentStatusEnum.CAPACITY_PLANNING_LOCKED,
  ];
  canEdit: boolean = false;
  canAcceptReject: boolean = false;

  constructor(public service: PLAssignmentManagerService) {}

  updateRequirements(event: any, item: PLAssignmentProposalItem) {
    event.stopPropagation();
    event.preventDefault();

    if (!item.numRequirements) {
      // fetch data
      item._loading = true;

      const query = {
        uuid: item.uuid,
      };
      this.service
        .fetchAssignmentProposals(query)
        .subscribe((proposalRaw: any) => {
          item.unmetRequirements = [];
          item.metRequirements = [];
          proposalRaw.requirements.forEach((req: PLAssignmentRequirement) => {
            if (this.service.isRequirementMet(req)) {
              item.metRequirements.push(req);
            } else {
              item.unmetRequirements.push(req);
            }
          });
          item.numRequirements = item.unmetRequirements.length;
          item._loading = false;
        });
    }
  }

  openEditAssignment(assignmentProposalItem) {
    this.addEdit.emit(assignmentProposalItem);
  }

  openProviderProfile(assignmentProposalItem) {
    const url = `/c/provider/${assignmentProposalItem.providerUuid}`;
    window.open(url, '_blank');
  }

  openSFDCRecord(assignmentProposalItem) {
    const url = `https://plearn.lightning.force.com/lightning/r/Contact/${assignmentProposalItem.providerObj.salesforce_id}/view`;
    window.open(url, '_blank');
  }

  openMetabaseReport(assignmentProposalItem) {
    const url = this.service.getProviderDashboardUrl(
      assignmentProposalItem.providerUuid,
    );
    window.open(url, '_blank');
  }

  acceptProposal(assignmentProposalItem) {
    this.accept.emit(assignmentProposalItem);
  }

  rejectProposal(assignmentProposalItem) {
    this.reject.emit(assignmentProposalItem);
  }
}
