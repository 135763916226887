import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, first, map } from 'rxjs/operators';
import {
  PLMayService,
  UserType,
} from '@root/src/lib-components/pl-may/pl-may.service';
import { AppStore } from '../../appstore.model';
import { selectCurrentUser } from '../../common/store';
@Injectable({
  providedIn: 'root',
})
export class PLClientListGuard implements CanActivate {
  constructor(
    private store: Store<AppStore>,
    private router: Router,
    private plMay: PLMayService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Only redirect if we're at the root client path
    if (state.url !== '/client/list') {
      return true;
    }

    return this.store.select(selectCurrentUser).pipe(
      filter(user => !!user && !!user.uuid),
      first(),
      map(user => {
        const shouldGoToCaseloadClients = [
          UserType.PROVIDER,
          UserType.THERAPIST,
        ].includes(this.plMay.getUserType(user));

        return shouldGoToCaseloadClients
          ? this.router.createUrlTree(['/client/list/caseload-clients'])
          : this.router.createUrlTree(['/client/list/all-clients']);
      }),
    );
  }
}
