import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { PLReferral } from '@root/src/app/common/interfaces';
import {
  PLGraphQLService,
  PLHttpService,
  PLUrlsService,
} from '@root/src/lib-components';

@Component({
  selector: 'pl-referral-bulk-action-modal',
  templateUrl: './referral-bulk-action-modal.component.html',
  styleUrls: ['./referral-bulk-action-modal.component.less'],
})
export class ReferralBulkActionModalComponent implements OnInit {
  referralControl = new FormControl('', Validators.required);
  reasons = [];
  noHoldReferrals = [];
  noDeleteReferrals = [];
  holdReferrals = 0;
  deleteReferrals = 0;

  constructor(
    private dialogRef: MatDialogRef<ReferralBulkActionModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public modalInput: {
      referrals: PLReferral[];
      title: string;
      action: string;
      submitLabel: string;
    },
    private plHttpService: PLHttpService,
    private plUrlsService: PLUrlsService,
    private plGraphQLService: PLGraphQLService,
  ) {
    const queryAction =
      this.modalInput.action === 'onHold' ? 'ON_HOLD' : 'DELETE';
    this.plHttpService
      .get(
        '',
        {},
        `${this.plUrlsService.urls.referralsStatusReasons}?query=${queryAction}`,
      )
      .subscribe(res => {
        this.reasons = res.reasons;
      });
  }

  ngOnInit(): void {
    this.noHoldReferrals = this.modalInput.referrals.filter(
      referral =>
        referral.state === 'DELETED' ||
        referral.state === 'CONVERTED' ||
        referral.isRsmSchool,
    );

    this.noDeleteReferrals = this.modalInput.referrals.filter(
      referral => referral.state === 'CONVERTED',
    );

    this.holdReferrals = this.modalInput.referrals.filter(
      referral =>
        !this.noHoldReferrals.some(
          noHoldReferral => noHoldReferral.id === referral.id,
        ),
    ).length;

    this.deleteReferrals = this.modalInput.referrals.filter(
      referral =>
        !this.noDeleteReferrals.some(
          noDeleteReferral => noDeleteReferral.id === referral.id,
        ),
    ).length;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submitBulkAction() {
    if (this.modalInput.action === 'onHold') {
      const referralsToOnHold = this.modalInput.referrals.filter(
        referral =>
          !this.noHoldReferrals.some(
            noHoldReferral => noHoldReferral.id === referral.id,
          ),
      );
      const variables = {
        ids: referralsToOnHold.map(referral => referral.id),
        reason: this.referralControl.value,
      };
      this.plGraphQLService
        .query(GQL_MUTATE_REFERRAL_HOLD, variables)
        .pipe(first())
        .subscribe(result => {
          this.dialogRef.close({
            result: !result.onHoldReferrals.errors ? 'success' : 'error',
          });
        });
    } else if (this.modalInput.action === 'releaseHold') {
      const referralsIds = this.modalInput.referrals.map(
        referral => referral.id,
      );
      const variables = {
        ids: referralsIds,
      };
      this.plGraphQLService
        .query(GQL_MUTATE_REFERRAL_RELEASE_HOLD, variables)
        .pipe(first())
        .subscribe(result => {
          this.dialogRef.close({
            result: !result.releaseOnHoldReferrals.errors ? 'success' : 'error',
          });
        });
    } else if (this.modalInput.action === 'delete') {
      const referralsToDelete = this.modalInput.referrals.filter(
        referral =>
          !this.noDeleteReferrals.some(
            noDeleteReferral => noDeleteReferral.id === referral.id,
          ),
      );
      const variables = {
        ids: referralsToDelete.map(referral => referral.id),
        reason: this.referralControl.value,
      };
      this.plGraphQLService
        .query(GQL_MUTATE_REFERRAL_DELETE, variables)
        .pipe(first())
        .subscribe(result => {
          this.dialogRef.close({
            result: !result.deleteReferrals.errors ? 'success' : 'error',
          });
        });
    }
  }
}

const GQL_MUTATE_REFERRAL_HOLD = `
  mutation onHoldReferrals($ids: [ID!]!, $reason: String!) {
    onHoldReferrals(input: {ids: $ids, reason: $reason}) {
      errors {
        code
      }
    }
  }
`;

const GQL_MUTATE_REFERRAL_RELEASE_HOLD = `
  mutation releaseOnHoldReferrals($ids: [ID!]!) {
    releaseOnHoldReferrals(input: {ids: $ids}) {
      errors {
        code
      }
    }
  }
`;

const GQL_MUTATE_REFERRAL_DELETE = `
  mutation deleteReferrals($ids: [ID!]!, $reason: String!) {
    deleteReferrals(input: {ids: $ids, reason: $reason}) {
      errors {
        code
      }
    }
  }
`;
