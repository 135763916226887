<div class="container" mat-dialog-content>
  <div class="title">
    <h3>{{ modalInput.title }}</h3>
    <button
      #closeButton
      mat-icon-button
      (click)="onNoClick()"
      class="close-button"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="divider"></div>

  <form (ngSubmit)="submitBulkAction()">
    <p *ngIf="modalInput.action === 'onHold'">
      You are about to put
      <strong>{{ holdReferrals }}</strong> referrals on hold.
    </p>
    <p *ngIf="modalInput.action === 'releaseHold'">
      You are about to remove the on-hold status for
      <strong>{{ holdReferrals }}</strong> referrals.
    </p>
    <p *ngIf="modalInput.action === 'delete'">
      You are about to delete
      <strong>{{ deleteReferrals }}</strong> referrals.<br />Please select a
      reason.
    </p>
    <mat-form-field
      *ngIf="modalInput.action === 'onHold'"
      class="reason"
      appearance="outline"
      cdkFocusInitial
    >
      <mat-label>Reason for hold</mat-label>
      <mat-select [formControl]="referralControl" required>
        <mat-option *ngFor="let reason of reasons" [value]="reason.value">
          {{ reason.label }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="referralControl.invalid">Reason is required</mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="modalInput.action === 'delete'"
      class="reason"
      appearance="outline"
      cdkFocusInitial
    >
      <mat-label>Reason for delete</mat-label>
      <mat-select [formControl]="referralControl" required>
        <mat-option *ngFor="let reason of reasons" [value]="reason.value">
          {{ reason.label }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="referralControl.invalid">Reason is required</mat-error>
    </mat-form-field>
    <div
      *ngIf="this.modalInput.action === 'onHold' && noHoldReferrals.length"
      class="disclaimer"
    >
      <p>
        <strong
          >{{ noHoldReferrals.length }} referrals will not be put on
          hold</strong
        >
        because they have already been converted, deleted, on hold or are RSM
        referrals.
      </p>
    </div>
    <div
      *ngIf="this.modalInput.action === 'delete' && noDeleteReferrals.length"
      class="disclaimer"
    >
      <p>
        <strong
          >{{ noDeleteReferrals.length }} referrals will not be deleted</strong
        >
        because they have already been converted.
      </p>
    </div>
    <div *ngIf="modalInput.action === 'delete'" class="disclaimer">
      <p>
        <strong>This action can not be undone.</strong><br />
        You will need to re-upload or import these referrals if they are
        required in the future.
      </p>
    </div>
    <div class="button-container">
      <button type="button" mat-button color="primary" (click)="onNoClick()">
        Cancel
      </button>
      <button
        type="submit"
        mat-button
        color="primary"
        [disabled]="
          (modalInput.action === 'onHold' &&
            (referralControl.invalid || holdReferrals === 0)) ||
          (modalInput.action === 'delete' &&
            (referralControl.invalid || deleteReferrals === 0))
        "
        class="submit-button"
      >
        {{ modalInput.submitLabel }}
      </button>
    </div>
  </form>
</div>
